/* Handsontable styles */
/*@import '~handsontable/dist/handsontable.full.css';*/

:root {
    --alabaster: #f9fafb;
    --allports: #006c9c;
    --black: #000000;
    --black-haze: #f4f6f8;
    --cardinal-pink: #890c58;
    --cardinal-pink-2: #890c587a;
    --cardinal-pink-3: #890c5814;
    --cerulean: #00b8d9;
    --cerulean-2: #00b8d93d;
    --cerulean-3: #00b8d929;
    --charade: #212b36;
    --deep-sea-green: #0a5554;
    --indochine: #b76e00;
    --jungle-green: #36b37e;
    --jungle-green-2: #36b37e3d;
    --jungle-green-3: #36b37e29;
    --manatee: #919eab3d;
    --manatee-2: #919eab;
    --manatee-3: #919eab52;
    --manatee-4: #919eab29;
    --manatee-5: #919eab7a;
    --manatee-6: #919eabcc;
    --outrageous-orange: #ff5630;
    --outrageous-orange-2: #ff563029;
    --pale-sky: #637381;
    --text: #ffffff;
    --thunderbird: #b71d18;
    --tropical-rain-forest: #1b806a;
    --web-orange: #ffab0029;
    --white: #ffffff3d;

    --font-size-l: 13px;
    --font-size-m: 12px;
    --font-size-s: 10px;
    --font-size-xl: 14px;
    --font-size-xxl: 16px;
    --font-size-xxxl: 18px;
    --font-size-xxxxl: 24px;
    --font-size-xs: 12px;

    --alabaster: #f9fafb;
    --allports: #006c9c;
    --black: #000000;
    --black-haze: #f4f6f8;
    --cardinal-pink: #890c58;
    --cardinal-pink-2: #890c587a;
    --cerulean: #00b8d9;
    --cerulean-2: #00b8d93d;
    --cerulean-3: #00b8d929;
    --charade: #212b36;
    --jungle-green: #36b37e;
    --jungle-green-2: #36b37e3d;
    --manatee: #919eab3d;
    --manatee-2: #919eab;
    --manatee-3: #919eab52;
    --manatee-4: #919eab7a;
    --manatee-5: #919eab29;
    --manatee-6: #919eab14;
    --outrageous-orange: #ff5630;
    --outrageous-orange-2: #ff563029;
    --pale-sky: #637381;
    --thunderbird: #b71d18;
    --white: #ffffff;

    --font-size-l: 13px;
    --font-size-m: 12px;
    --font-size-s: 10px;
    --font-size-xl: 14px;
    --font-size-xxl: 16px;
    --font-size-xxxl: 18px;
    --font-size-xxxxl: 24px;


    --font-family-public_sans: "Public Sans", Helvetica;
}

.publicsans-normal-pale-sky-12px {
    color: var(--pale-sky);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.publicsans-normal-charade-14px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.publicsans-normal-manatee-12px {
    color: var(--manatee-2);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
    background: #fff;
}

.publicsans-semi-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}

.publicsans-semi-bold-pale-sky-13px {
    color: var(--pale-sky);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 600;
}

.publicsans-bold-charade-18px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-charade-12px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-custom-12px {
    color: #1939b7;
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.publicsans-normal-pale-sky-10px {
    color: var(--pale-sky);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.publicsans-normal-charade-16px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
}

.publicsans-bold-charade-13px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.publicsans-semi-bold-white-14px {
    color: var(--text);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}

.publicsans-normal-charade-13px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.publicsans-bold-cardinal-pink-13px {
    color: var(--cardinal-pink);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-white-24px {
    color: var(--text);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-allports-13px {
    color: var(--allports);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-thunderbird-12px {
    color: var(--thunderbird);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-white-14px {
    color: var(--text);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.publicsans-semi-bold-charade-14px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}

.publicsans-bold-white-9px {
    color: var(--text);
    font-family: var(--font-family-public_sans);
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
}

.publicsans-semi-bold-deep-sea-green-14px {
    color: var(--deep-sea-green);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}

.publicsans-bold-charade-20px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-charade-14px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-allports-12px {
    color: var(--allports);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-indochine-12px {
    color: var(--indochine);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-tropical-rain-forest-12px {
    color: var(--tropical-rain-forest);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-12px {
    font-family: var(--font-family-public_sans) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
}

.publicsans-bold-charade-24px {
    color: var(--charade);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 700;
}

.publicsans-bold-manatee-13px {
    color: var(--manatee-6);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.publicsans-normal-manatee-10px {
    color: var(--manatee-6);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.publicsans-normal-cardinal-pink-10px {
    color: var(--cardinal-pink);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.publicsans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.publicsans-semi-bold-manatee-14px {
    color: var(--manatee-2);
    font-family: var(--font-family-public_sans);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}


body {
    background-color: #FFFFFF !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

bs-days-calendar-view + bs-days-calendar-view {
    margin-left: 10px;
}

bs-days-calendar-view {
    display: inline-block;
    border-radius: 4px 0 0 4px;
}

@media (max-width: 768px) {
    .btn-md-icon {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
}

/*.grid-stack {*/
/*    background: darkgrey;*/
/*}*/

.grid-stack-item-content {
    text-align: center;
    background-color: #fff;
    overflow: hidden !important;
}

:root .grid-stack-item > .ui-resizable-handle {
    filter: none
}

.grid-stack {
    position: relative
}

.grid-stack.grid-stack-rtl {
    direction: ltr
}

.grid-stack.grid-stack-rtl > .grid-stack-item {
    direction: rtl
}

.grid-stack .grid-stack-placeholder > .placeholder-content {
    border: 1px dashed #d3d3d3;
    margin: 0;
    position: absolute;
    width: auto;
    z-index: 0 !important;
    text-align: center
}

.grid-stack > .grid-stack-item {
    min-width: 8.3333333333%;
    position: absolute;
    padding: 0
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
    margin: 0;
    position: absolute;
    width: auto;
    overflow-x: hidden;
    overflow-y: auto;

    background-color: var(--white);
    /*border:none;*/
    /*border: 2px dashed;*/
    border-color: var(--manatee-5);
    border-radius: 16px;
    box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px #919eab33;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content-data {
    margin: 0;
    position: absolute;
    width: auto;
    overflow-x: hidden;
    overflow-y: auto;

    background-color: var(--white);
    border-color: var(--manatee-5);
    border-radius: 16px;
}

.grid-stack > .grid-stack-item > .ui-resizable-handle {
    position: absolute;
    font-size: .1px;
    display: block;
    -ms-touch-action: none;
    touch-action: none
}

.grid-stack > .grid-stack-item.ui-resizable-autohide > .ui-resizable-handle, .grid-stack > .grid-stack-item.ui-resizable-disabled > .ui-resizable-handle {
    display: none
}

.grid-stack > .grid-stack-item.ui-draggable-dragging, .grid-stack > .grid-stack-item.ui-resizable-resizing {
    z-index: 100
}

.grid-stack > .grid-stack-item.ui-draggable-dragging > .grid-stack-item-content, .grid-stack > .grid-stack-item.ui-resizable-resizing > .grid-stack-item-content {
    box-shadow: 1px 4px 6px rgba(0, 0, 0, .2);
    opacity: .8
}

.grid-stack > .grid-stack-item > .ui-resizable-se, .grid-stack > .grid-stack-item > .ui-resizable-sw {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzAwQjhEOSIvPiA8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE1IiByeD0iNy41IiBzdHJva2U9IndoaXRlIi8+IDwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.grid-stack > .grid-stack-item > .ui-resizable-se {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.grid-stack > .grid-stack-item > .ui-resizable-nw {
    cursor: nw-resize;
    width: 20px;
    height: 20px;
    top: 0
}

.grid-stack > .grid-stack-item > .ui-resizable-n {
    cursor: n-resize;
    height: 10px;
    top: 0;
    left: 25px;
    right: 25px
}

.grid-stack > .grid-stack-item > .ui-resizable-ne {
    cursor: ne-resize;
    width: 20px;
    height: 20px;
    top: 0
}

.grid-stack > .grid-stack-item > .ui-resizable-e {
    cursor: e-resize;
    width: 10px;
    top: 15px;
    bottom: 15px
}

.grid-stack > .grid-stack-item > .ui-resizable-se {
    cursor: se-resize;
    width: 20px;
    height: 20px
}

.grid-stack > .grid-stack-item > .ui-resizable-s {
    cursor: s-resize;
    height: 10px;
    left: 25px;
    bottom: 0;
    right: 25px
}

.grid-stack > .grid-stack-item > .ui-resizable-sw {
    cursor: sw-resize;
    width: 20px;
    height: 20px;
    bottom: 0
}

.grid-stack > .grid-stack-item > .ui-resizable-w {
    cursor: w-resize;
    width: 10px;
    top: 15px;
    bottom: 15px
}

.grid-stack > .grid-stack-item.ui-draggable-dragging > .ui-resizable-handle {
    display: none !important
}

.grid-stack > .grid-stack-item[gs-w="0"] {
    width: 0%
}

.grid-stack > .grid-stack-item[gs-x="0"] {
    left: 0
}

.grid-stack > .grid-stack-item[gs-min-w="0"] {
    min-width: 0
}

.grid-stack > .grid-stack-item[gs-max-w="0"] {
    max-width: 0%
}

.grid-stack > .grid-stack-item[gs-w="1"] {
    width: 8.3333333333%
}

.grid-stack > .grid-stack-item[gs-x="1"] {
    left: 8.3333333333%
}

.grid-stack > .grid-stack-item[gs-min-w="1"] {
    min-width: 8.3333333333%
}

.grid-stack > .grid-stack-item[gs-max-w="1"] {
    max-width: 8.3333333333%
}

.grid-stack > .grid-stack-item[gs-w="2"] {
    width: 16.6666666667%
}

.grid-stack > .grid-stack-item[gs-x="2"] {
    left: 16.6666666667%
}

.grid-stack > .grid-stack-item[gs-min-w="2"] {
    min-width: 16.6666666667%
}

.grid-stack > .grid-stack-item[gs-max-w="2"] {
    max-width: 16.6666666667%
}

.grid-stack > .grid-stack-item[gs-w="3"] {
    width: 25%
}

.grid-stack > .grid-stack-item[gs-x="3"] {
    left: 25%
}

.grid-stack > .grid-stack-item[gs-min-w="3"] {
    min-width: 25%
}

.grid-stack > .grid-stack-item[gs-max-w="3"] {
    max-width: 25%
}

.grid-stack > .grid-stack-item[gs-w="4"] {
    width: 33.3333333333%
}

.grid-stack > .grid-stack-item[gs-x="4"] {
    left: 33.3333333333%
}

.grid-stack > .grid-stack-item[gs-min-w="4"] {
    min-width: 33.3333333333%
}

.grid-stack > .grid-stack-item[gs-max-w="4"] {
    max-width: 33.3333333333%
}

.grid-stack > .grid-stack-item[gs-w="5"] {
    width: 41.6666666667%
}

.grid-stack > .grid-stack-item[gs-x="5"] {
    left: 41.6666666667%
}

.grid-stack > .grid-stack-item[gs-min-w="5"] {
    min-width: 41.6666666667%
}

.grid-stack > .grid-stack-item[gs-max-w="5"] {
    max-width: 41.6666666667%
}

.grid-stack > .grid-stack-item[gs-w="6"] {
    width: 50%
}

.grid-stack > .grid-stack-item[gs-x="6"] {
    left: 50%
}

.grid-stack > .grid-stack-item[gs-min-w="6"] {
    min-width: 50%
}

.grid-stack > .grid-stack-item[gs-max-w="6"] {
    max-width: 50%
}

.grid-stack > .grid-stack-item[gs-w="7"] {
    width: 58.3333333333%
}

.grid-stack > .grid-stack-item[gs-x="7"] {
    left: 58.3333333333%
}

.grid-stack > .grid-stack-item[gs-min-w="7"] {
    min-width: 58.3333333333%
}

.grid-stack > .grid-stack-item[gs-max-w="7"] {
    max-width: 58.3333333333%
}

.grid-stack > .grid-stack-item[gs-w="8"] {
    width: 66.6666666667%
}

.grid-stack > .grid-stack-item[gs-x="8"] {
    left: 66.6666666667%
}

.grid-stack > .grid-stack-item[gs-min-w="8"] {
    min-width: 66.6666666667%
}

.grid-stack > .grid-stack-item[gs-max-w="8"] {
    max-width: 66.6666666667%
}

.grid-stack > .grid-stack-item[gs-w="9"] {
    width: 75%
}

.grid-stack > .grid-stack-item[gs-x="9"] {
    left: 75%
}

.grid-stack > .grid-stack-item[gs-min-w="9"] {
    min-width: 75%
}

.grid-stack > .grid-stack-item[gs-max-w="9"] {
    max-width: 75%
}

.grid-stack > .grid-stack-item[gs-w="10"] {
    width: 83.3333333333%
}

.grid-stack > .grid-stack-item[gs-x="10"] {
    left: 83.3333333333%
}

.grid-stack > .grid-stack-item[gs-min-w="10"] {
    min-width: 83.3333333333%
}

.grid-stack > .grid-stack-item[gs-max-w="10"] {
    max-width: 83.3333333333%
}

.grid-stack > .grid-stack-item[gs-w="11"] {
    width: 91.6666666667%
}

.grid-stack > .grid-stack-item[gs-x="11"] {
    left: 91.6666666667%
}

.grid-stack > .grid-stack-item[gs-min-w="11"] {
    min-width: 91.6666666667%
}

.grid-stack > .grid-stack-item[gs-max-w="11"] {
    max-width: 91.6666666667%
}

.grid-stack > .grid-stack-item[gs-w="12"] {
    width: 100%
}

.grid-stack > .grid-stack-item[gs-x="12"] {
    left: 100%
}

.grid-stack > .grid-stack-item[gs-min-w="12"] {
    min-width: 100%
}

.grid-stack > .grid-stack-item[gs-max-w="12"] {
    max-width: 100%
}

.grid-stack.grid-stack-1 > .grid-stack-item {
    min-width: 100%
}

.grid-stack.grid-stack-1 > .grid-stack-item[gs-w="1"] {
    width: 100%
}

.grid-stack.grid-stack-1 > .grid-stack-item[gs-x="1"] {
    left: 100%
}

.grid-stack.grid-stack-1 > .grid-stack-item[gs-min-w="1"] {
    min-width: 100%
}

.grid-stack.grid-stack-1 > .grid-stack-item[gs-max-w="1"] {
    max-width: 100%
}

.grid-stack.grid-stack-animate, .grid-stack.grid-stack-animate .grid-stack-item {
    -webkit-transition: left .3s, top .3s, height .3s, width .3s;
    -moz-transition: left .3s, top .3s, height .3s, width .3s;
    -ms-transition: left .3s, top .3s, height .3s, width .3s;
    -o-transition: left .3s, top .3s, height .3s, width .3s;
    transition: left .3s, top .3s, height .3s, width .3s
}

.grid-stack.grid-stack-animate .grid-stack-item.grid-stack-placeholder, .grid-stack.grid-stack-animate .grid-stack-item.ui-draggable-dragging, .grid-stack.grid-stack-animate .grid-stack-item.ui-resizable-resizing {
    -webkit-transition: left 0s, top 0s, height 0s, width 0s;
    -moz-transition: left 0s, top 0s, height 0s, width 0s;
    -ms-transition: left 0s, top 0s, height 0s, width 0s;
    -o-transition: left 0s, top 0s, height 0s, width 0s;
    transition: left 0s, top 0s, height 0s, width 0s
}

.grid-stack.ui-droppable.ui-droppable-over > :not(.ui-droppable) {
    pointer-events: none
}


.container {
    height: 100%;
    width: 100%;
    position: absolute;
}

.p-2 {
    padding: 5px;

}


.menu-item .menu-link {
    display: flex !important;
    align-items: center !important;
    flex: 0 0 100% !important;
    outline: none !important;
}

.aside-menu .menu .menu-item .menu-link, .aside-menu .menu .menu-item .menu-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.aside-menu .menu-item .menu-link {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}


/*.subheader {*/
/*    display: flex !important;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    align-items: center !important;*/
/*    padding: 10px 40px !important;*/
/*    gap: 832px;*/
/*    position: absolute !important;*/
/*    height: 82px !important;*/
/*    left: 88px !important;*/
/*    right: 0px !important;*/
/*    top: 0px !important;*/
/*    background: #FFFFFF;*/
/*}*/

.dashboard-outer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    gap: 832px;
    position: absolute;
    height: 82px;
    left: 88px;
    right: 0px;
    top: 0px;
    background: #FFFFFF;
}

.dashboard-inner-content {
    /* Breadcrumb */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 1272px;
    height: 62px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}


.dashboard-left {
    /* Sol */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 700px;
    height: 62px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner {
    /* Breadcrumb */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 700px;
    height: 62px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack {
    /* stack */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 700px;
    height: 62px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}


.dashboard-left-inner-stack-links {
    /* links */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 277px;
    height: 18px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-links-link {
    /* link */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 54px;
    height: 18px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-links-link-icon-div {
    /* icons/ic_windows */


    width: 12px;
    height: 12px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-links-link-icon {
    position: absolute;
}


.dashboard-left-inner-stack-links-link-text {
    /* text */


    width: 38px;
    height: 18px;

    /* Caption */

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */


    /* Text/Light/Primary */

    color: #212B36;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-links-link-active-text {
    /* text */


    width: 90px;
    height: 18px;

    /* Caption */

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */


    /* Text/Light/Disabled */

    color: #919EAB;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-links-link-dot {
    /* dot */


    width: 4px;
    height: 4px;
    background: #919EAB;
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom {
    /* Frame 15 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 40px;

    width: 623px;
    height: 40px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-text {
    /* Frame 17 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;

    width: 150px;
    height: 36px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-right {
    /* Frame 16 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 433px;
    height: 32px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-right-first-button {
    /* Button */


    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    gap: 8px;

    width: 146px;
    height: 30px;

    /* Components/Button/Outlined */

    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-right-bottom-icon {
    /* start icon */


    width: 18px;
    height: 22px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-right-first-button-icon-s {
    /* shape */


    /*position: absolute;*/
    /*left: 12.5%;*/
    /*right: 8.33%;*/
    /*top: 20.83%;*/
    /*bottom: 20.83%;*/

    /* Text/Light/Primary */

    /*background: #212B36;*/
}

.dashboard-left-inner-stack-left-bottom-right-bottom-text {
    /* label */


    /*width: 100px;*/
    height: 22px;

    /* Components/Button/Small */

    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */


    /* Text/Light/Primary */

    color: #212B36;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-right-second-button {
    /* Button */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    gap: 8px;

    width: 117px;
    height: 30px;

    /* Transparent/Info/16% */

    background: rgba(0, 184, 217, 0.16);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-right-second-button-icon-s {
    /* shape */


    /*position: absolute;*/
    /*left: 12.5%;*/
    /*right: 12.5%;*/
    /*top: 8.33%;*/
    /*bottom: 8.33%;*/

    /*!* Info/Dark *!*/

    /*background: #006C9C;*/
}

.dashboard-left-inner-stack-left-bottom-right-second-bottom-text {
    /* label */


    width: 71px;
    height: 22px;

    /* Components/Button/Small */

    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */


    /* Info/Dark */

    color: #006C9C;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.dashboard-left-inner-stack-left-bottom-text span {
    width: 151px;
    height: 36px;

    /* H4 */

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */


    /* Text/Light/Primary */

    color: #212B36;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dahboard-title {
    /*width: 150px;*/
    height: 36px;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px;
    line-height: 36px;
    color: #212B36 !important;
    order: 0;
    flex-grow: 0;
}

.dashboard-nav-mini {
    position: absolute !important;
    width: 88px !important;
    left: 0px !important;
    top: 0px !important;
    bottom: 0px !important;

    background: #FFFFFF !important;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
    box-shadow: none !important;
}

.dashboard-nav-mini .divider {
    position: absolute;
    width: 1px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.dashboard-nav-mini .divider .line {
    position: absolute;
    width: 1px;
    left: calc(50% - 1px / 2);
    top: 0px;
    bottom: 0px;

    border: 1px dashed rgba(145, 158, 171, 0.24);
}

.dashboard-nav-mini .stack {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px !important;

    position: absolute !important;
    height: 1024px !important;
    left: 0px !important;
    right: 0px !important;
    top: 0px !important;
}

.dashboard-nav-mini .stack .logo {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 16px 0px !important;

    width: 88px !important;
    height: 82px !important;


    /* Inside auto layout */

    flex: none !important;
    order: 0 !important;
    align-self: stretch !important;
    flex-grow: 0 !important;
}

.dashboard-nav-mini .stack .logo .logo-inner {
    width: 40px !important;
    height: 40px !important;

    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;
}

.dashboard-nav-mini .stack .frame {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    padding: 0px !important;

    width: 88px !important;


    /* Inside auto layout */

    flex: none !important;
    order: 1 !important;
    flex-grow: 1 !important;
}


.dashboard-nav-mini .stack .frame .menu .top {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px 6px 16px !important;
    gap: 4px !important;

    width: 88px !important;
    /*height: 330px !important;*/


    /* Inside auto layout */

    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;
}

.dashboard-nav-mini .stack .frame .menu .sub {
    display: flex !important;
    bottom: 0px !important;
    position: fixed !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px 6px 16px !important;
    gap: 4px !important;
    width: 88px !important;
    z-index: 10;
}

.dashboard-nav-mini .stack .frame .menu .active {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 4px !important;

    /* Transparent/Primary/8% */

    background: rgba(137, 12, 88, 0.08);
    border-radius: 8px;

}


.dashboard-nav-mini .stack .frame .menu .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px 4px;
    gap: 4px;
    isolation: isolate;

    width: 76px;
    height: 54px;

    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.dashboard-nav-mini .stack .frame .menu .line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;

    width: 24px;
    height: 10px;


    /* Inside auto layout */

    flex: none;
    order: 12;
    flex-grow: 0;
}

.dashboard-nav-mini .stack .frame .menu .line .line-inner {
    width: 24px;
    height: 1px;

    /* Components/Divider */

    background: rgba(145, 158, 171, 0.24);

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 100%;
}

.dashboard-nav-mini .stack .frame .menu .item .icon {
    width: 22px;
    height: 22px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
}

.dashboard-nav-mini .stack .frame .menu .item .text {
    width: 76px;
    height: 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Text/Light/Secondary */

    color: #637381;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;
}

.dashboard-nav-mini .stack .frame .menu .active .text {
    width: 76px;
    height: 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary/Main */

    color: #890C58;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;
}

.dashboard-nav-mini .stack .frame .menu .item .arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 6px 0px 0px;
    position: absolute;

    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;

    margin-left: 60% !important;
    margin-bottom: 25% !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xLjY2NjY3IDkuNjY2NjdDMS41MTA5IDkuNjY2OTggMS4zNTk5NCA5LjYxMjczIDEuMjQgOS41MTMzNEMxLjEwMzY2IDkuNDAwMzEgMS4wMTc5IDkuMjM3NjcgMS4wMDE2NCA5LjA2MTMyQzAuOTg1MzgzIDguODg0OTcgMS4wMzk5NiA4LjcwOTM5IDEuMTUzMzMgOC41NzMzNEw0LjE0IDVMMS4yNiAxLjQyQzEuMTQ4MTUgMS4yODIyNyAxLjA5NTgyIDEuMTA1NjQgMS4xMTQ1OSAwLjkyOTIxNkMxLjEzMzM2IDAuNzUyNzg4IDEuMjIxNjggMC41OTExMTkgMS4zNiAwLjQ4MDAwNEMxLjQ5OTQ0IDAuMzU3MzExIDEuNjgzNzUgMC4yOTgzNTQgMS44Njg1MiAwLjMxNzMzNkMyLjA1MzI4IDAuMzM2MzE5IDIuMjIxNzUgMC40MzE1MjEgMi4zMzMzMyAwLjU4MDAwNEw1LjU1MzMzIDQuNThDNS43NTU1NSA0LjgyNiA1Ljc1NTU1IDUuMTgwNjcgNS41NTMzMyA1LjQyNjY3TDIuMjIgOS40MjY2N0MyLjA4NDM2IDkuNTkwMyAxLjg3ODgyIDkuNjc5NDUgMS42NjY2NyA5LjY2NjY3WiIgZmlsbD0iIzYzNzM4MSIvPiA8L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: 16px;
    height: 16px;
}

.dashboard-nav-mini .stack .frame .menu .active .item .arrow {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMS42NjY2NyA5LjY2NjY3QzEuNTEwOSA5LjY2Njk4IDEuMzU5OTQgOS42MTI3MyAxLjI0IDkuNTEzMzRDMS4xMDM2NiA5LjQwMDMxIDEuMDE3OSA5LjIzNzY3IDEuMDAxNjQgOS4wNjEzMkMwLjk4NTM4MyA4Ljg4NDk3IDEuMDM5OTYgOC43MDkzOSAxLjE1MzMzIDguNTczMzRMNC4xNCA1TDEuMjYgMS40MkMxLjE0ODE1IDEuMjgyMjcgMS4wOTU4MiAxLjEwNTY0IDEuMTE0NTkgMC45MjkyMTZDMS4xMzMzNiAwLjc1Mjc4OCAxLjIyMTY4IDAuNTkxMTE5IDEuMzYgMC40ODAwMDRDMS40OTk0NCAwLjM1NzMxMSAxLjY4Mzc1IDAuMjk4MzU0IDEuODY4NTIgMC4zMTczMzZDMi4wNTMyOCAwLjMzNjMxOSAyLjIyMTc1IDAuNDMxNTIxIDIuMzMzMzMgMC41ODAwMDRMNS41NTMzMyA0LjU4QzUuNzU1NTUgNC44MjYgNS43NTU1NSA1LjE4MDY3IDUuNTUzMzMgNS40MjY2N0wyLjIyIDkuNDI2NjdDMi4wODQzNiA5LjU5MDMgMS44Nzg4MiA5LjY3OTQ1IDEuNjY2NjcgOS42NjY2N1oiIGZpbGw9IiM4OTBDNTgiLz4NCjwvc3ZnPg0K);
}

.dashboard-nav-mini .stack .frame .menu .item .number {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 6.5px;
    position: absolute;
    width: 15px;
    height: 15px;

    background: #FF5630;
    border-radius: 10px;
    margin-left: 19% !important;
    margin-bottom: 35% !important;
    font-style: normal;
    font-weight: 700;

    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    font-size: 10px;
}

.card {
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.toolbar-fixed .toolbar {
    box-shadow: none !important;
}

.container-fluid {
    padding: 0 !important;
}

.header-fixed .wrapper {
    padding-top: 70px !important;
}

.card-cursor {
    cursor: pointer;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    display: none !important;
}

.mapboxgl-popup-close-button {
    display: none !important;
}

.dashboard-nav-mini .stack .frame .menu .item-sub {
    display: flex;
    flex-direction: column;
    width: 76px;
    border-radius: 8px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.dashboard-nav-mini .stack .frame .menu .item-sub .text-sub {
    /*width: 76px;*/
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: left;
    color: #637381;
    order: 1;
    z-index: 1;
}


.dashboard-nav-mini .stack .frame .menu .menu-sub-accordion-right .active {
    background-color: transparent !important;
    border-radius: 5px !important;

}

.dashboard-nav-mini .stack .frame .menu .item-sub .arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 6px 0px 0px;
    position: absolute;

    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;

    margin-left: 60% !important;
    margin-bottom: 25% !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xLjY2NjY3IDkuNjY2NjdDMS41MTA5IDkuNjY2OTggMS4zNTk5NCA5LjYxMjczIDEuMjQgOS41MTMzNEMxLjEwMzY2IDkuNDAwMzEgMS4wMTc5IDkuMjM3NjcgMS4wMDE2NCA5LjA2MTMyQzAuOTg1MzgzIDguODg0OTcgMS4wMzk5NiA4LjcwOTM5IDEuMTUzMzMgOC41NzMzNEw0LjE0IDVMMS4yNiAxLjQyQzEuMTQ4MTUgMS4yODIyNyAxLjA5NTgyIDEuMTA1NjQgMS4xMTQ1OSAwLjkyOTIxNkMxLjEzMzM2IDAuNzUyNzg4IDEuMjIxNjggMC41OTExMTkgMS4zNiAwLjQ4MDAwNEMxLjQ5OTQ0IDAuMzU3MzExIDEuNjgzNzUgMC4yOTgzNTQgMS44Njg1MiAwLjMxNzMzNkMyLjA1MzI4IDAuMzM2MzE5IDIuMjIxNzUgMC40MzE1MjEgMi4zMzMzMyAwLjU4MDAwNEw1LjU1MzMzIDQuNThDNS43NTU1NSA0LjgyNiA1Ljc1NTU1IDUuMTgwNjcgNS41NTMzMyA1LjQyNjY3TDIuMjIgOS40MjY2N0MyLjA4NDM2IDkuNTkwMyAxLjg3ODgyIDkuNjc5NDUgMS42NjY2NyA5LjY2NjY3WiIgZmlsbD0iIzYzNzM4MSIvPiA8L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: 16px;
    height: 16px;
}
/*.ngx-slider .ngx-slider-bar {*/
/*    background: #d9b1ca !important;*/
/*}*/
